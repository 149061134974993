import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_patient_form = _resolveComponent("patient-form")
  const _component_modal = _resolveComponent("modal")

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = $event => ($data.showModal = true)),
      class: "btn btn-secondary text-sm py-2 px-4"
    }, "+ Nuevo paciente"),
    _createVNode(_component_modal, {
      showModal: $data.showModal,
      onClose: _cache[2] || (_cache[2] = $event => ($data.showModal = false))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_patient_form, {
          onClose: _cache[1] || (_cache[1] = $event => {$data.showModal = false; this.$emit('reload')})
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["showModal"])
  ]))
}