import { createApp } from 'vue/dist/vue.esm-bundler.js';
import Counter from './components/counter.vue';
import Modal from './components/modal.vue';
import BtnViewProfileDoctor from './components/btn-view-profile-doctor.vue';
import Calendar from './components/calendar.vue';
import CalendarDay from './components/calendar-day.vue';
import CalendarAppointment from './components/calendar-appointment.vue';
import BtnAddDoctor from './components/btn-add-doctor.vue';
import BtnEditDoctor from './components/btn-edit-doctor.vue';
import DoctorForm from './components/doctor-form.vue';
import DoctorList from './components/doctor-list.vue';
import PatientList from './components/patient-list.vue';
import BtnViewProfilePatient from './components/btn-view-profile-patient.vue';
import BtnEditPatient from './components/btn-edit-patient.vue';
import PatientForm from './components/patient-form.vue';
import BtnAddPatient from './components/btn-add-patient.vue';
import AppointmentForm from './components/appointment-form.vue';
import BtnAddAppointment from './components/btn-add-appointment.vue';
import BtnEditAppointment from './components/btn-edit-appointment.vue';
import CalendarDoctor from './components/calendar-doctor.vue';
import CalendarDayDoctor from './components/calendar-day-doctor.vue';
import CalendarAppointmentDoctor from './components/calendar-appointment-doctor.vue';
import BtnAddRegister from './components/btn-add-register.vue';
import RegisterForm from './components/register-form.vue';
import BtnViewAll from './components/btn-view-all.vue';
import BtnViewRecord from './components/btn-view-record.vue';
import RecordDetail from './components/record-detail.vue';

require("../../../public/css/tailwind.css")

let app = createApp({});
app.component('counter', Counter);
app.component('modal', Modal);
app.component('btn-view-profile-doctor', BtnViewProfileDoctor);
app.component('calendar', Calendar);
app.component('calendar-day', CalendarDay);
app.component('calendar-appointment', CalendarAppointment);
app.component('btn-add-doctor', BtnAddDoctor);
app.component('btn-edit-doctor', BtnEditDoctor);
app.component('doctor-form', DoctorForm);
app.component('doctor-list', DoctorList);
app.component('patient-list', PatientList);
app.component('btn-view-profile-patient', BtnViewProfilePatient);
app.component('btn-edit-patient',BtnEditPatient);
app.component('patient-form', PatientForm);
app.component('btn-add-patient', BtnAddPatient);
app.component('appointment-form', AppointmentForm);
app.component('btn-add-appointment', BtnAddAppointment);
app.component('btn-edit-appointment', BtnEditAppointment);
app.component('calendar-doctor', CalendarDoctor);
app.component('calendar-day-doctor', CalendarDayDoctor);
app.component('calendar-appointment-doctor', CalendarAppointmentDoctor);
app.component('btn-add-register', BtnAddRegister);
app.component('register-form', RegisterForm);
app.component('btn-view-all', BtnViewAll);
app.component('btn-view-record', BtnViewRecord);
app.component('record-detail', RecordDetail);
app.mount('#app');