import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "h-full" }
const _hoisted_2 = { class: "font-medium text-center text-sm" }
const _hoisted_3 = { class: "border border-gray-100 mt-[18px]" }
const _hoisted_4 = ["data-time"]
const _hoisted_5 = { class: "flex gap-1 items-stretch h-full" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_calendar_appointment_doctor = _resolveComponent("calendar-appointment-doctor")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString($options.toDay($props.date)), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.hours, (hour) => {
        return (_openBlock(), _createElementBlock("div", {
          key: hour,
          class: "h-[34px] py-0.5 border-b border-gray-100",
          "data-time": hour
        }, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.filterByTime(hour), (appointment) => {
              return (_openBlock(), _createBlock(_component_calendar_appointment_doctor, {
                key: appointment.name,
                appointment: appointment,
                class: "text-center py-1 px-2 h-full"
              }, null, 8 /* PROPS */, ["appointment"]))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ], 8 /* PROPS */, _hoisted_4))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}